import React from 'react'
import { graphql, Link } from 'gatsby'
import HeroBanner from '../components/HeroBanner'
import Layout from '../components/Layout'
import './daily-workout.scss'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandPointLeft,
  faHandPointRight,
  faBars
} from '@fortawesome/free-solid-svg-icons'

const DailyWorkout = ({ data, pageContext }) => {
  const { next, previous } = pageContext
  const workout = data.wordpressWpDailyworkouts
  return (
    <Layout>
      <div className="DailyWorkout">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Workout of the Day"
          caption="Ready to get your daily fit on?"
        />
        <div className="container content">
          <Row className="navigationRow">
            <Col md={4}>
              {next ? (
                <Link to={`/dailyworkout/${next.acf.workout_day}`}>
                  
                  <Button color="link">
                  <FontAwesomeIcon icon={faHandPointLeft} />
                    {next.title} ({next.acf.workout_day})
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col md={4} className="text-center">
            <Link to="/dailyworkout">
                  <Button color="link">
                  <FontAwesomeIcon icon={faBars} />
                    List Workouts
                  </Button>
                </Link>
            </Col>
            <Col md={4} className="text-right">
              {previous ? (
                <Link to={`/dailyworkout/${previous.acf.workout_day}`}>
                  <Button color="link">
                    {previous.title} ({previous.acf.workout_day})
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </Button>
                </Link>
              ) : null}
            </Col>
          </Row>
          <div className="container">
            <h1 dangerouslySetInnerHTML={{ __html: workout.title }}></h1>
            <div dangerouslySetInnerHTML={{ __html: workout.content }}></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DailyWorkout

export const query = graphql`
  query DailyWorkout($id: String!) {
    wordpressWpDailyworkouts(id: { eq: $id }) {
      title
      content
    }
    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
